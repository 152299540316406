// Global
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

// Components
import { Header } from '@components/Header';
import { Footer } from '@components/Footer';

// Redux
import { selectProfile } from '@features/profile/profile';
import { selectMove } from '@features/move/move';

// Utils
import { checkAuthentication } from '@utils/checkAuthentication';
import { isMoveActive } from '@utils/checkMoveStatus';

interface ICommonProps {
  component?: React.ReactNode;
  title?: string;
}

/**
 * Common Template
 * This is used for pages accessible for both authenticated and unauthenticated users
 */
export const Common = ({ component, title }: ICommonProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { value: profile } = useSelector(selectProfile);
  const { value: move } = useSelector(selectMove);

  /**
   * This checks if profile exists, if so user is logged in.
   * Otherwise user may be logged out, or logged in but navigating in a new session so check against api/auth/check
   */
  useEffect(() => {
    const authenticate = async () => {
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        setIsAuthenticated(true);
      }
    };

    !!profile ? setIsAuthenticated(true) : authenticate();
  }, [profile]);

  return (
    <>
      <Helmet>
        <title>{title || 'Home'}</title>
      </Helmet>
      <div className="flex flex-col">
        <Header loggedIn={isAuthenticated} />
        {component}
        <Footer isMoving={isMoveActive(move.status)} />
      </div>
    </>
  );
};
