// Global
import { lazy } from 'react';

// Pages
import { Dashboard } from './pages/dashboard';
import { MyTeam } from './pages/myTeam';
import { SignIn } from './pages/signin';
import { FindAPropertyPage } from '@pages/wizard/pages/FindAProperty';
import { BroadbandPage } from '@pages/wizard/pages/Broadband';
import { CompletePage } from '@pages/wizard/pages/Complete';
import { ConveyancingPage } from '@pages/wizard/pages/Conveyancing';
import { EnergyPage } from '@pages/wizard/pages/Energy';
import { ExchangeContractsPage } from '@pages/wizard/pages/ExchangeContracts';
import { FinancePage } from '@pages/wizard/pages/Finance';
import { GetMoveReadyPage } from '@pages/wizard/pages/GetMoveReady';
import { InsurancePage } from '@pages/wizard/pages/Insurance';
import { RemovalsPage } from '@pages/wizard/pages/Removals';
import { SaleAgreedPage } from '@pages/wizard/pages/SaleAgreed';
import { SignContractsPage } from '@pages/wizard/pages/SignContracts';
import { ForgotPasswordConfirm } from '@pages/forgotPasswordConfirm';
import { Messaging } from '@pages/messaging';
import { ServicesEnergyPage } from '@pages/services/Energy';
import { ServicesBroadbandPage } from '@pages/services/Broadband';

// Lazy-loaded Pages
const Onboarding = lazy(() => import('./pages/onboarding'));
const OnboardingConfirmation = lazy(() => import('./pages/onboardingConfirmation'));
const Terms = lazy(() => import('./pages/terms'));
const ForgotPasswordUpdate = lazy(() => import('./pages/forgotPasswordUpdate'));
const ForgotPasswordMaybe = lazy(() => import('./pages/forgotPasswordMaybe'));
const Privacy = lazy(() => import('./pages/privacy'));

type RouteConfiguration = {
  path: string;
  title: string;
  component: any;
  private?: boolean;
  public?: boolean;
};

export const routeConfig: RouteConfiguration[] = [
  {
    path: '/terms',
    title: 'Terms & Conditions',
    component: Terms,
    private: true,
    public: true,
  },
  {
    path: '/privacy',
    title: 'Privacy Policy',
    component: Privacy,
    private: true,
    public: true,
  },
  {
    path: '/signin',
    title: 'Sign in',
    component: SignIn,
    public: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    component: ForgotPasswordMaybe,
    public: true,
  },
  {
    path: '/confirm-forgot-password',
    title: 'Confirm Forgot Password',
    component: ForgotPasswordConfirm,
    public: true,
  },
  {
    path: '/update-password',
    title: 'Update Password',
    component: ForgotPasswordUpdate,
    public: true,
  },
  {
    path: '/invitations',
    title: 'Welcome to Home',
    component: Onboarding,
    public: true,
  },
  {
    path: '/confirmation',
    title: 'Welcome to Home',
    component: OnboardingConfirmation,
    public: true,
  },
  {
    path: '/wizard/find-a-property',
    title: 'My Move Wizard | Find a Property',
    component: FindAPropertyPage,
    private: true,
  },
  {
    path: '/wizard/broadband',
    title: 'My Move Wizard | Broadband',
    component: BroadbandPage,
    private: true,
  },
  {
    path: '/wizard/complete',
    title: 'My Move Wizard | Complete',
    component: CompletePage,
    private: true,
  },
  {
    path: '/wizard/conveyancing',
    title: 'My Move Wizard | Conveyancing',
    component: ConveyancingPage,
    private: true,
  },
  {
    path: '/wizard/exchange-contracts',
    title: 'My Move Wizard | Exchange Contracts',
    component: ExchangeContractsPage,
    private: true,
  },
  {
    path: '/wizard/get-ready',
    title: 'My Move Wizard | Get Move Ready',
    component: GetMoveReadyPage,
    private: true,
  },
  {
    path: '/wizard/insurance',
    title: 'My Move Wizard | Insurance',
    component: InsurancePage,
    private: true,
  },
  {
    path: '/wizard/removals',
    title: 'My Move Wizard | Removals',
    component: RemovalsPage,
    private: true,
  },
  {
    path: '/wizard/sale-agreed',
    title: 'My Move Wizard | Sale Agreed',
    component: SaleAgreedPage,
    private: true,
  },
  {
    path: '/wizard/sign-contracts',
    title: 'My Move Wizard | Sign Contracts',
    component: SignContractsPage,
    private: true,
  },
  {
    path: '/wizard/finance',
    title: 'My Move Wizard | Finance',
    component: FinancePage,
    private: true,
  },
  {
    path: '/wizard/energy',
    title: 'My Move Wizard | Energy',
    component: EnergyPage,
    private: true,
  },
  {
    path: '/services/energy',
    title: 'Energy',
    component: ServicesEnergyPage,
    private: true,
  },
  {
    path: '/services/broadband',
    title: 'Broadband',
    component: ServicesBroadbandPage,
    private: true,
  },
  {
    path: '/your-team',
    title: 'My Team',
    component: MyTeam,
    private: true,
  },
  {
    path: '/messages',
    title: 'Messages',
    component: Messaging,
    private: true,
  },
  {
    path: '/',
    title: 'Home',
    component: Dashboard,
    private: true,
  },
];

type RedirectConfiguration = {
  from: string;
  to: string;
};

export const routeRedirects: RedirectConfiguration[] = [
  {
    from: '/wizard',
    to: '/wizard/find-a-property',
  },
];
