// Global
import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';

// Utils
import { generateHoneycombKey } from '@utils/generateHoneycombKey';

const apiKey = generateHoneycombKey(window.location.href);

if (!!apiKey) {
  const sdk = new HoneycombWebSDK({
    apiKey,
    serviceName: 'home-frontend',
    instrumentations: [
      new XMLHttpRequestInstrumentation({
        ignoreNetworkEvents: true,
      }),
    ],
    webVitalsInstrumentationConfig: {
      enabled: false, // disable web vitals instrumentation
    },
  });

  sdk.start();
}
