import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { moveStagesReducer } from '@features/moveStages/moveStages';
import { meReducer } from '@features/profile/profile';
import { moveReducer } from '@features/move/move';
import { messageRoomsReducer } from '@features/messageRooms/messageRooms';
import { onboardingReducer } from '@features/onboarding/onboarding';
import { messagesReducer } from '@features/messages/messages';
import { autoFetchMessagesMiddleware } from '@features/middleware';

const rootReducer = combineReducers({
  moveStages: moveStagesReducer,
  me: meReducer,
  move: moveReducer,
  messageRooms: messageRoomsReducer,
  actualMessages: messagesReducer,
  onboarding: onboardingReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(autoFetchMessagesMiddleware),
  });
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type InitialState = Partial<RootState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
