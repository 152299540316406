// Global
import { useEffect } from 'react';

// Components
import { Spinner } from '@components/Spinner';

// Images
import { HouseCross } from '@images/icons';

type HoldingProps = {
  title: string;
  text?: string;
};

export const Holding = ({ title, text }: HoldingProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative box-border flex min-h-screen w-screen flex-col items-center justify-center gap-10 bg-brand p-5">
      <HouseCross className="w-24 fill-white" />
      <h2 className="text-center text-3xl font-medium text-white">{title}</h2>
      {text && <p className="text-center text-lg text-white">{text}</p>}
      <Spinner />
    </div>
  );
};
